
import { defineComponent } from 'vue'

import { Tag } from '@/types/Tag'

import TagEditModal from '@/components/pages/dashboard/tags/modals/TagEditModal.vue'

export default defineComponent({
  name: 'Tags',
  components: {
    TagEditModal
  },
  data () {
    return {
      query: '',
      disabled: false,
      tags: [] as Tag[],
      pages: [] as number[],
      pageSelector: 1,
      page: 0
    }
  },
  computed: {
    isPrevPage (): boolean {
      return this.page > 0
    },
    isNextPage (): boolean {
      return this.page < this.pages.length - 1
    }
  },
  methods: {
    async fetch (page = 0) {
      this.disabled = true
      const limitPerPage = 100
      try {
        const { tags, total, offset } = await Tag.get(this.query, (page * limitPerPage))
        this.tags = tags
        this.page = Math.floor(offset / limitPerPage)
        this.pages = [...Array(Math.ceil(total / limitPerPage)).keys()]
      } catch (error) {
        this.$store.commit('onError', error)
      }
      this.disabled = false
    },
    editTag (id?: number) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (this.$refs.modal as any).open(id)
    },
    copyTag (url: string) {
      navigator.clipboard.writeText(url)
    },
    nextPage () {
      if (this.isNextPage) {
        this.fetch(this.page + 1)
      }
    },
    prevPage () {
      if (this.isPrevPage) {
        this.fetch(this.page - 1)
      }
    },
    closed () {
      this.fetch(this.page)
    },
    goToPage (event: Event) {
      (event.target as any).blur()
      this.pageSelector = (event.target as any).value
      if (this.pageSelector > this.pages.length) {
        this.pageSelector = this.pages.length
      } else if (this.pageSelector < 1) {
        this.pageSelector = 1
      }
      this.fetch(this.pageSelector - 1)
    }
  },
  watch: {
    page (page: number) {
      this.pageSelector = page + 1
    }
  },
  mounted () {
    this.fetch()
  }
})
