
import { defineComponent } from 'vue'
import Modal from 'bootstrap/js/dist/modal'

import { Tag, TagCreationAttributes, TagUpdateAttributes } from '@/types/Tag'

export default defineComponent({
  name: 'TagEditModal',
  emits: [
    'success'
  ],
  data () {
    return {
      modal: undefined,
      error: undefined,
      disabled: false,
      tag: {
        id: undefined,
        name: undefined,
        description: undefined,
        created: undefined,
        modified: undefined
      }
    } as {
      modal?: Modal,
      error?: string,
      disabled: boolean,
      tag: {
        id?: number,
        name?: string,
        description?: string | null,
        created?: string,
        modified?: string
      }
    }
  },
  computed: {
    isEditMode (): boolean {
      return !!this.tag.id
    }
  },
  methods: {
    async open (id?: number) {
      this.disabled = true
      this.error = undefined
      this.loadTag(id ? await Tag.getById(id) : undefined)
      if (!this.modal) {
        this.modal = new Modal(this.$refs.modal as HTMLElement, {})
      }
      this.modal.show()
    },
    async createTag () {
      this.disabled = true
      this.tag.description = this.tag.description ? this.tag.description : null
      try {
        this.loadTag(await Tag.create(this.tag as TagCreationAttributes))
        this.success()
      } catch (error) {
        console.log(error)
        this.error = (error as Error).message
        this.disabled = false
      }
    },
    async updateTag () {
      this.disabled = true
      this.tag.description = this.tag.description ? this.tag.description : null
      try {
        this.loadTag(await Tag.update(this.tag.id!, this.tag as TagUpdateAttributes))
        this.success()
      } catch (error) {
        console.log(error)
        this.error = (error as Error).message
        this.disabled = false
      }
    },
    loadTag (tag?: Tag) {
      this.tag.id = tag ? tag.id : undefined
      this.tag.name = tag ? tag.name : undefined
      this.tag.description = tag ? tag.description : undefined
      this.tag.created = tag ? tag.created.toLocaleString() : undefined
      this.tag.modified = tag ? tag.modified.toLocaleString() : undefined
      this.disabled = false
    },
    success () {
      if (this.modal) {
        this.modal.hide()
      }
      this.disabled = false
      this.$emit('success')
    }
  },
  beforeUnmount () {
    if (this.modal) {
      this.modal.dispose()
    }
  }
})
